@keyframes three-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}

.three-bounce-spinner {
  width: 100%;
  text-align: center;
}

.three-bounce-spinner > .bounce {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 4px;
  background-color: grey;
  border-radius: 100%;
  animation: three-bouncedelay 1.4s infinite ease-in-out both;
}

.three-bounce-spinner .bounce1 {
  animation-delay: -0.32s;
}

.three-bounce-spinner .bounce2 {
  animation-delay: -0.16s;
}
